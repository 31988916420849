<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <validation-observer ref="simpleRules">
      <button
        class="btn btn-gradient-primary mb-1"
        @click="SubmitNew()"
      >
        {{ $t('saveData') }}
      </button>
      <b-row class="match-height">
        <b-col md="6">
          <div
            class="card"
          >
            <div class="header-cardx p-2">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="mb-0">
                  {{ $t('pricing') }}
                </h3>

                <a
                  href="javascript:void(0)"
                  @click="addRow()"
                ><i class="fal fa-plus" /> {{ $t('addRow') }}</a>
              </div>
            </div>

            <div class="pl-2 pr-2">
              <b-table
                responsive="sm"
                :fields="fields"
                :items="items"
                class="mb-2"
                show-empty
              >

                <template #empty>
                  <div class="text-center">
                    <img
                      src="@/assets/images/anan-img/empty/empty.png"
                      alt="empty"
                      width="180px"
                    >
                    <p>No Data</p>
                  </div>
                </template>

                <template #cell(weight)="data">
                  <b-form-input
                    v-model="data.item.weight"
                    type="number"
                    class="text-center"
                  />
                </template>

                <template #cell(price)="data">
                  <b-form-input
                    v-model="data.item.price"
                    type="number"
                    class="text-center"
                  />
                </template>

                <template #cell(action)="data">
                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger cursor-pointer"
                    @click="removeRow(data.index)"
                  />
                </template>
              </b-table>
            </div>

          </div>
        </b-col>

        <b-col md="6">
          <div class="card">
            <div class="header-cardx p-2">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="mb-0">
                  {{ $t('selectFreeWarehouseShipping') }}
                </h3>

                <a
                  href="javascript:void(0)"
                  @click="modalTrans()"
                ><i class="fal fa-plus" /> {{ $t('selectShipping') }}</a>
              </div>
            </div>

            <div class="pl-2 pr-2">
              <b-table
                responsive="sm"
                :fields="fieldsTrans"
                :items="itemsTrans"
                class="mb-2"
                show-empty
              >

                <template #empty>
                  <div class="text-center">
                    <img
                      src="@/assets/images/anan-img/empty/empty.png"
                      alt="empty"
                      width="180px"
                    >
                    <p>No Data</p>
                  </div>
                </template>
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

              </b-table>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-modal
        ref="my-trans"
        :title="$t('key-71')"
        @ok="submitShipping()"
      >
        <b-table
          responsive="sm"
          :fields="fieldsTrans2"
          :items="paginatedItems"
          :selectable="selectable"
          :checked-rows.sync="selectedRows"
          show-empty
        >

          <template #empty>
            <div class="text-center">
              <img
                src="@/assets/images/anan-img/empty/empty.png"
                alt="empty"
                width="180px"
              >
              <p>No Data</p>
            </div>
          </template>

          <template #cell(checked)="data">
            <b-form-checkbox
              v-model="data.item.checked"
              :value="1"
              :unchecked-value="0"
              @change="toggleChecked(data.item.checked, 'checked',data.item._id)"
            />
          </template>
          <template #cell(index)="data">
            {{ currentPage * perPage - perPage + data.index + 1 }}
          </template>

          <template #cell(name)="data">
            {{ data.item.name }}
          </template>

          <template #cell(price)="data">
            <b-form-input
              v-model="data.item.price"
              type="number"
              class="text-center"
            />
          </template>

          <template #cell(action)="data">
            <feather-icon
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              @click="removeRow(data.index)"
            />
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="itemsTrans2.length"
          :per-page="perPage"
          align="center"
        />
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BTable,
  BFormCheckbox,
  BModal,
  BPagination,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BTable,
    BFormCheckbox,
    BModal,
    BPagination,
  },
  data() {
    return {
      required,
      file: null,
      fullname: null,
      tel: null,
      tel2: null,
      different: null,
      invited_by: null,
      role: null,
      username: null,
      password: null,
      sub_role: [],
      Address: [],

      // Add Address
      name: null,
      phone: null,
      address: null,
      province: null,
      district: null,
      subdistrict: null,
      postalcode: null,

      selectedFiles: [],
      uploadedImages: [],

      selectable: true,
      selectedRows: [],
      selectAll: false,

      items: [],

      itemsTrans: [],

      itemsTrans2: [
        { nameTrans: 'รับสินค้าเอง' },
        { nameTrans: 'บริษัทจัดส่ง 690 บาท' },
        { nameTrans: 'PL ขนส่ง' },
        { nameTrans: 'ไปรษณีย์ (EMS)' },
        { nameTrans: 'นิ่มซี่เส็ง ภาคเหนือ' },
        { nameTrans: 'นิ่มเอ็กเพลส' },
        { nameTrans: 'Kerry' },
        { nameTrans: 'Flash Express' },
        { nameTrans: 'J&T Express' },
        { nameTrans: 'DHL' },
        { nameTrans: 'Best Express' },
        { nameTrans: 'ขนส่งเอกชนอื่นๆ (ระบุช่องหมายเหตุ)' },
        { nameTrans: 'ส่งจากไทยไปจีน' },
      ],
      perPage: 7,
      currentPage: 2,
      subrole: [],
      module_list: [],
      module_use: '',
      group_user_list: [],
      group_use: '',
      group_user_type: 1,
      group_userloading: false,
      category: '',
      ShippingList: [],
      checked: 0,
      rate: [{}],
      free_shipping: [{}],
      // checked: [],
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'weight', label: this.$t('weight'), sortable: false, thStyle: { width: '25%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'price', label: this.$t('price'), sortable: false, thStyle: { width: '70%' }, thClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-right',
        },
      ]
    },
    fieldsTrans() {
      return [
        {
          key: 'index', label: this.$t('number'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'name', label: this.$t('firstName'), sortable: false, thStyle: { width: '85%' }, thClass: 'text-center',
        },
      ]
    },
    fieldsTrans2() {
      return [
        { key: 'checked', label: '' },
        {
          key: 'index', label: this.$t('number'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'name', label: this.$t('firstName'), sortable: false, thStyle: { width: '85%' }, thClass: 'text-center',
        },
      ]
    },

    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.perPage
      const endIndex = startIndex + this.perPage
      return this.itemsTrans2.slice(startIndex, endIndex)
    },
  },
  mounted() {
    this.sub_role = []
    this.getShippingList()
    this.getData('all')
  },
  methods: {
    async getShippingList() {
      try {
        const { data: res } = await this.$http.get('/warehouse/shipping_showall')
        this.itemsTrans2 = res.data
      } catch (e) {
        console.log(e)
      }
    },
    modalTrans() {
      this.$refs['my-trans'].show()
    },
    addRow() {
      const newRow = {
        weight: 0,
        price: 0,
      }
      this.items.push(newRow)
    },
    removeRow(index) {
      this.items.splice(index, 1)
    },
    SubRole(data) {
      this.sub_role = this.sub_role.filter(item => item !== data.level)
      if (data.status) {
        this.sub_role.push(data.level)
      } else {
        this.sub_role = this.sub_role.filter(item => item !== data.level)
      }
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success && this.group_use) {
          const subroleJs = this.subrole.filter(item => item.status)
          const subrole = subroleJs.map(item => item.level)
          const formData = {
            role: this.role.value,
            email: this.email,
            fullname: this.fullname,
            tel: this.tel,
            tel2: this.tel2,
            different: this.different,
            invited_by: this.invited_by,
            username: this.username,
            password: this.password,
            // sub_role: this.sub_role,
            sub_role: subrole.toString(),
            module_id: this.module_use.id,
            Address: this.Address,
            group_use: this.group_use.id,
            group_user_type: this.group_user_type,
            itemsCar: this.itemsCar,
            itemsShip: this.itemsShip,
          }
          // console.log(formData)
          this.$http
            .post('/users/Adminstore', formData)
            .then(() => {
              this.Success('เพิ่มผู้ใช้งาน')
              this.role = null
              this.email = null
              this.fullname = null
              this.tel = null
              this.tel2 = null
              this.different = null
              this.invited_by = null
              this.username = null
              this.password = null
              this.$router.push({ name: 'admin-employee' })
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        }
      })
    },
    async getData(type) {
      this.isActive = type
      try {
        const { data: res } = await this.$http.get('/warehouse/warehouse_show')
        this.items = res.rate || []
        this.itemsTrans = res.free_shipping
      } catch (e) {
        console.log(e)
      }
    },
    toggleChecked(data, fields, ID) {
      if (data || data === 0) {
        const obj = {
          data,
          fields,
          ID,
        }
        this.$http.post('/warehouse/warehouse_update_field', obj)
      }
    },
    submitShipping() {
      const arrChecked = this.itemsTrans2.filter(ele => ele.checked === 1)
      this.itemsTrans = arrChecked
    },
    CalModule() {
      const moduleArr = this.module_use.sub_role.split(',')
      this.subrole.forEach(item => {
        if (moduleArr.includes(item.level.toString())) {
          this.$set(item, 'status', true)
        } else {
          this.$set(item, 'status', false)
        }
      })
    },

    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    SubmitNew() {
      const obj = {
        rate: this.items,
        free_shipping: this.itemsTrans,
      }
      this.$http.post('/warehouse/warehouse_update', obj)
        .then(() => {
          this.$bvToast.toast('บันทึกขอมูลสำเร็จ', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
            .catch(error => {
              console.log(error)
              // this.SwalError(error.response.data.message)
            })
        })
    },
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
